<template>
  <div id="app">
    <!-- 版心 -->
    <div class="content">
      <!-- 标题 -->
       <div id="fixPara">
        <div class="title_box">
          <span>
            {{ video_title }}
          </span>
        </div>
        <!-- 播放器 -->
        <video
          id="play"
          controls="controls"
          controlslist="nodownload"
          :src="video_url"
        >
          <!-- <source :src="getPlayVideo()" type="video/mp4"> -->
        </video>
      </div>

      <!-- 课程列表 -->
      <div class="course_list_box">
        <div class="course_list" v-for="item in newArr" :key="item.id">
          <div class="course_title_box">
            <div>
              <img src="/pc/img/zhangjie.png" alt="勤学教育" />{{ item.name }}
            </div>
            <div></div>
          </div>

          <div
            class="course_link_box"
            v-for="el in item.video_list"
            :key="el.id"
            @click="changeUrl(el)"
          >
            <div class="course_link">
              <img src="/pc//img/bofang.png" alt="勤学教育" />
              <div> 
              </div>
              <img src="/pc/img/lijibofang.png" alt="勤学教育" />
            </div>
          </div>
        </div>
      </div>
      <!-- 底部内容 -->
      <div class="foot_box">
        <div class="foot_content">
          <div>联系电话：19939365723</div>
          <div>备案号：湘ICP备 2023001774号</div>
          <div>服务时间：周一至周五9:00-12:00 14:00-17:00</div>
          <div>联系地址：长沙高新开发区麓谷大道658号湖南麓谷信息港</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      course_id: "", //视频id
      vodPlayerJs: "https://player.polyv.net/script/player.js",
      vid: "88083abbf5bcf1356e05d39666be527a_8",
      video_url: "", // 视频地址
      video_title: "",
      one_bar: [], //一级导航
      two_bar: {}, //二级导航
      // is_one: "", // 是否选中一级导航
      // is_two: "", // 是否选中一级导航
      try_course: [], // 试听课程
      // video_cate_list: {},
      course: [], // 课程
      newArr: [],
      two_nav: "",
    };
  },

  mounted() {},

  watch: {
    mvUrl(newValue, oldValue) {
      let video = this.$refs.video;
      video.src = this.video_url;
    },
  },
  methods: {
    // 播放器动态传参
    getPlayVideo() {
      // var _this = this
      // 获取视频路径
      // var video = this.video_url;

      //得到 viode id
      videoElem = document.getElementById("play");
      //在video 写入（<source>）
      videoElem.innerHTML =
        '<source class="source" src="' + this.video_url + '" type="video/mp4">';
      //视频自动播放
      // videoElem.play();
      videoElem.pause(); //视频暂停
    },
    // 获取地址栏参数
    GetQueryString(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
      var r = window.location.search.substr(1).match(reg);
      if (r != null) return unescape(r[2]);
      return null;
    },
    // 获取试听课程详情视频
    getVideo() {
      var data = {
        // type: 2,
        course_id: this.course_id,
      };
      axiosPostRequst("/api/courses/detail", data).then((res) => {
        this.video_url = res.data.info.video_url;
        this.video_title = res.data.info.name.replace(
          /^试听[- ]+([^- ])(.*)/,
          "$1$2"
        );

        document.getElementById("play").load();
        this.getPlayVideo();
      });
    },
    getOneNav() {
      // 一级导航接口
      var data = {
        pid: 1235,
      };
      axiosPostRequst("/api/courses/course_cate", data).then((res) => {
        if (parseInt(res.code) == 1) {
          this.one_bar = res.data;
          this.is_one = res.data[0].course_category_id;
          this.getTwoNav(this.two_nav);
        }
      });
    },
    // 二级导航
    getTwoNav(id) {
      // this.two_bar = {}
      this.video_cate_list = {};
      var data = {
        pid: id,
      };
      axiosPostRequst("/api/courses/course_cate", data).then((res) => {
        if (parseInt(res.code) == 1) {
          this.getCourse(id);
          // var video_cate_list = [];
          this.two_bar = res.data;
          this.course = res.data;
          this.is_two = res.data[0].course_category_id;
        }
      });
    },

    // 试听课程接口
    getCourse(course_category_id) {
      var data = {
        course_category_id: course_category_id,
        type: 2,
      };
      axiosPostRequst("/api/courses", data).then((res) => {
        this.newArr = [];
        if (parseInt(res.code) === 1) {
          this.course.forEach((el) => {
            el.video_list = [];
            res.data.list.map(function(item) {
              if (el.course_category_id == item.course_category_id) {
                if (item) {
                  item.name = item.name.replace(
                    /^试听[- ]+([^- ])(.*)/,
                    "$1$2"
                  );
                  el.video_list.push(item);
                } else {
                }
              }
            });
            this.newArr.push(el);
            this.$forceUpdate();
          });
          this.newArr = this.newArr.filter(
            (item) => item.video_list.length !== 0
          );

        }
      });
    },
    // 点击切换视频
    changeUrl(ele) {
      this.video_url = ele.video_url;
      this.video_title = ele.name;
    },
    scroll() {
      window.οnlοad = function() {
        var oDiv = document.getElementById("fixPara"),
          H = 0,
          Y = oDiv;
        while (Y) {
          H += Y.offsetTop;
          Y = Y.offsetParent;
        }
        window.onscroll = function() {
          var s = document.body.scrollTop || document.documentElement.scrollTop;
          if (s > H) {
            oDiv.style = "position:fixed;top:0;";
          } else {
            oDiv.style = "";
          }
        };
      };
    },
  },
  created() {
    this.scroll();

    // 当前页面id
    var myurl = this.GetQueryString("info_id");
    var myurl2 = this.GetQueryString("two_nav");

    if (myurl != null && myurl.toString().length > 0) {
      this.course_id = myurl;
      this.two_nav = myurl2;
      // localStorage.setItem("natActive", myurl2)
    }
    // this.setRem()
    this.getVideo();
    this.getPlayVideo();
    this.getOneNav();
  },
  destroyed() {
    // if (this.player) {
    //     this.player.destroy();
    // }
  },
};
</script>

<style lang="scss" scoped>
/* 版心 */

.content {
  position: relative;
  width: 100%;
  /* height: 100vh; */
  height: 100%;
  background: #f5f5f5;
}

/* 标题 */

.title_box {
  width: 100%;
  /* height: 3.5rem; */
  /* background: pink; */
  max-height: 4.38rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 0.06rem solid #eee;
  background: #fff;
  font-family: PingFangSC-Regular, PingFang SC;
}

.title_box span {
  width: 21.44rem;
  margin: 0.88rem 0;
  /* overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; */
  /* text-align: center; */
}

.title {
  font-size: 1rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  position: fixed;
}

/* 播放器 */

#play {
  width: 100%;
  height: 13.75rem;
  /* position: fixed; */
}

/* 课程列表 */

.course_list_box {
  width: 100%;
  /* height: 500px; */
  background: #fff;
}

.course_list {
  width: 21.44rem;
  /* height: 1000px; */
  margin: auto;
}

/* 课程标题 */

.course_title_box {
  position: relative;
  width: 100%;
  height: 3.75rem;
  display: flex;
  align-items: center;
}

.course_title_box div:nth-child(1) img {
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 0.63rem;
}

.course_title_box div:nth-child(1) {
  display: flex;
  align-items: center;
  font-size: 1.13rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 1.56rem;
}

.course_title_box div:nth-child(2) {
  position: absolute;
  bottom: 0;
  width: 22.44rem;
  height: 0.06rem;
  background: rgba(0, 0, 0, 0.1);
}

.course_link_box {
  max-height: 4.38rem;
  /* height: 3.13rem; */
  border-bottom: 0.06rem solid rgba(0, 0, 0, 0.1);
}

.course_link {
  width: 21.44rem;
  /* height: 3.13rem; */
  margin: 0.88rem 0;
  display: flex;
  align-items: center;
}

.course_link div {
  width: 17.88rem;
  font-size: 1rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  margin: 0 1.19rem 0 0.88rem;
}

/* 底部内容 */

.foot_box {
  /* opacity: 0; */
  width: 100%;
  height: 7.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #232526;
  /* position: absolute;
    bottom: 0; */
}

.foot_content {
  width: 19.38rem;
  height: 4.25rem;
  text-align: center;
  font-size: 0.75rem;
  color: #fff;
}

#fixPara {
  background: #fff;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
}
</style>
